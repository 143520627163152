import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor() {}

  static isMobile() {
    return window.innerWidth < 768 ? true : false;
  }
}
