export interface Theme {
  name: string;
  properties: any;
}

export const light: Theme = {
  name: 'light',
  properties: {
    '--font-color': '#121212',
    '--font-color-highlight': '#0832ff',
    '--font-color-subheader': '#939394',
    '--bg-color': ' #fff',
    '--box-color': ' #202022',
    '--opaque-box-color': '#202022',
    '--icon-color': '#0832ff',
    '--shadow-color': '#121212',
    '--btn-color': '#fff',
    '--btn-bg-color': '#121212',
  },
};

export const dark: Theme = {
  name: 'dark',
  properties: {
    '--font-color': '#fff',
    '--font-color-highlight': '#e4fd97',
    '--font-color-subheader': '#939394',
    '--bg-color': ' #121212',
    '--box-color': ' #202022',
    '--opaque-box-color': 'rgba(60, 60, 60, 0.4)',
    '--icon-color': '#0832ff',
    '--shadow-color': '#fff',
    '--btn-color': '#121212',
    '--btn-bg-color': '#fff',
  },
};
