import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/theme/theme.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
  constructor(private themeService: ThemeService) {}
  ngOnInit(): void {}

  public currentMouse;

  MouseMove(event) {
    this.currentMouse = event;
  }

  setSvgColor() {
    return this.themeService.setSvgColor('#0832ff', '#e4fd97');
  }
}
