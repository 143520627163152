<div id="about" class="page black">
  <div class="container">
    <div>
      <h2>We want to bridge the gap between design & coding.</h2>
      <p>
        You have an idea but you don’t know if it takes too much time to code?
        What technology you need to bring your idea to come alive? This is where
        we step in. We will help you to how to unlock the power of digital.
        Always working side by side.
      </p>
<!--      <a class="white-btn" href="#">Explore the demos</a>-->
    </div>

    <div class="circles">
      <!-- move extra space caused by circles overlapping to the top: [ngStyle]="{ paddingTop: this.circle.r * 0.5 }" -->
      <app-circles></app-circles>
    </div>
  </div>
</div>
