<div id="work" class="page black">
  <div class="container">
    <h2>Selected work</h2>
    <div *ngFor="let w of works" class="inner-container">
      <div
        routerLink="/work-details/{{ w.id }}"
        class="work-card"
        *ngIf="works.indexOf(w) % 2 == 0"
      >
        <img src="{{ w.images[0] }}" />
        <div>
          <h3>CLIENT: {{ w.client }}</h3>
          <h2>{{ w.projectName }}</h2>
        </div>
      </div>
      <div
        routerLink="/work-details/{{ w.id }}"
        class="work-card"
        *ngIf="works.indexOf(w) % 2 != 0"
      >
        <div>
          <h3>CLIENT: {{ w.client }}</h3>
          <h2>{{ w.projectName }}</h2>
        </div>
        <img src="{{ w.images[0] }}" />
      </div>
    </div>
  </div>
  <!-- <router-outlet></router-outlet> -->
</div>
