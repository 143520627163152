import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ThemeService } from 'src/app/theme/theme.service';

@Component({
  selector: 'logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  mode: string = 'dark';
  @Input() logoTextColor: string = 'currentColor';

  constructor(private themeService: ThemeService) {}

  ngOnInit(): void {}

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    window.scrollY > 100
      ? (this.logoTextColor = 'transparent')
      : (this.logoTextColor = 'currentColor');
  }

  toggleTheme() {
    if (this.themeService.isDarkTheme()) {
      this.themeService.setLightTheme();
    } else {
      this.themeService.setDarkTheme();
    }
    this.mode = this.themeService.getActiveTheme().name;
  }
}
