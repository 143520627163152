import { Component, OnInit, HostListener } from '@angular/core';
import Two from 'two.js';
import { ThemeService } from 'src/app/theme/theme.service';

@Component({
  selector: 'app-rectangle',
  templateUrl: './rectangle.component.html',
  styleUrls: ['./rectangle.component.scss'],
})
export class RectangleComponent implements OnInit {
  //Window
  private windowWidth; //Viewport Width
  private windowHeight; //Viewport Height
  private positionX = 0; //Placement of the center on X-axis
  private positionY = 0; //Placement of the center on Y-axis
  private target: Element;

  //Rectangles
  private settings; //this.settings object to store variables
  private two; //Instance of the this.two.js library
  private rects; //Group of all rectangles

  private tx;
  private ty; //Target values

  private gutterHeight = 1.7; // Height of empty space after rectangle

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    this.init();
  }

  init() {
    this.target = document.getElementById('rectangles');

    //Check initial window width
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;

    //Create canvas with this.two.js, clear an existing one if called again
    if (this.two) this.two.clear();

    //Set initial this.settings
    this.settings = {
      size: 60, //Size of the innermost rectangle
      step: 1.2, //Increase in size for each consecutive rectangle
      numberOf: 13, //Number of rectangles in total
      color: '#fff', //Border color
      width: 0.75, //Line width
      screw: 4, //Degrees of rotation to the previous rectangle
    };

    //Instantiate this.two
    this.two = new Two({
      fullscreen: false,
      width: this.windowWidth,
      height: this.windowHeight * this.gutterHeight, // before: 2
      type: Two.Types.svg,
    }).appendTo(this.target);

    //Create container for all the child rectangles
    this.rects = this.two.makeGroup();

    //Calculate size for the largest rectangle so that it always fits into the window

    this.settings.size = this.calculateSize();

    //Loop through all rectangles and add them to the container
    for (let i = 0; i <= this.settings.numberOf; i++) {
      //Calculate size where the length of the side of the rectangle is x longer than in the previous rectangle
      let size =
        this.settings.size + this.settings.step * this.settings.size * i;

      //Create the rectangle object
      let rect = this.two.makeRectangle(0, 0, size, size);
      rect.fill = 'rgba(255, 255, 255, 0.0)';
      rect.linewidth = this.settings.width;
      rect.stroke = this.setSvgColor();
      // rect.stroke = this.settings.color;

      let group = this.two.makeGroup(rect).center();
      group.rotation = this.degToRad(90 - i * this.settings.screw);
      group.addTo(this.rects);
    }

    //Call initial resize to position element
    this.resize();

    this.two
      .bind('update', (frameCount) => {
        let inertia = 0.2;

        for (let i = 0; i <= this.settings.numberOf; i++) {
          let rect = this.rects.children[i];
          inertia = 0.85 * inertia;
          rect.translation.x = this.inertiaTo(
            rect.translation.x,
            this.tx,
            inertia
          );
          rect.translation.y = this.inertiaTo(
            rect.translation.y,
            this.ty,
            inertia
          );
        }
      })
      .play();
  }

  calculateSize() {
    let w =
      this.windowWidth > this.windowHeight
        ? this.windowWidth
        : this.windowHeight;
    return w / 2.45 / this.settings.numberOf; //2.45 @ 1920px size = 60
  }

  //Converts Degrees to Radians (1° × π/180)
  degToRad(degrees) {
    return (degrees * Math.PI) / 180;
  }

  //Converts Radians to Degrees (1rad × 180/π = 57,296°)
  radToDeg(radians) {
    return (radians * 180) / Math.PI;
  }

  inertiaTo(current, target, amount) {
    if (amount == 1) return target;

    let distToGo = target - current;
    let delta = current + distToGo * amount;

    if (Math.abs(distToGo) < 0.01) delta = target;

    return delta;
  }

  //Moves the whole rectangle object. Occurs after init and when canvas is clicked
  @HostListener('window:mousemove', ['$event'])
  reposition(event) {
    if (
      event.clientY - this.target.getBoundingClientRect().top <
      this.windowHeight
    ) {
      this.tx = event.clientX;
      this.ty = event.clientY - this.target.getBoundingClientRect().top;

      let s = this.calculateSize();
      this.rects.scale = this.settings.size / s;
      this.settings.size = s;
    } else {
      this.tx = this.positionX;
      this.ty = this.positionY;
    }
  }

  @HostListener('window:touchmove', ['$event'])
  repositionOnTouch(event) {
    let touchmoveObj = {
      // clientX: event.originalEvent.touches[0].pageX,
      // clientY: event.originalEvent.touches[0].pageY,
      clientX: event.touches[0].clientX,
      clientY: event.touches[0].clientY,
    };
    this.reposition(touchmoveObj);
  }

  //Translates mousewheel change into degrees of rotation. Occurs when mousewheel input is received
  screw(delta) {
    //Slow down mousewheel action -- especially in Firefox -- can this be made browser independent?
    let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    delta = delta / (isFirefox ? 10 : 500);

    this.settings.screw += delta;
    for (let i = 0; i <= this.settings.numberOf; i++) {
      if (i == 0) continue;
      let rect = this.rects.children[i];
      rect.rotation = this.degToRad(90 - i * this.settings.screw);
    }
  }

  //Keeps track of viewport size. Occurs when browser is resized
  @HostListener('window:resize', ['$event'])
  resize() {
    //Store window size
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.two.width = this.windowWidth;
    this.two.height = this.windowHeight * this.gutterHeight; // before: 2

    this.positionX = this.windowWidth - 200;
    this.positionY = this.windowHeight - 70;
    let mousemoveObj = {
      clientX: this.positionX,
      clientY: this.positionY,
    };
    this.reposition(mousemoveObj);
  }

  // change rectangle color based on color theme
  @HostListener('window:change', ['$event'])
  setSvgColor() {
    let color = this.themeService.setSvgColor('#121212', '#fff');
    this.rects.stroke = color;
  }
}
