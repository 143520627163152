import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Work } from './sections/work/work.model';
import { WORKS } from './sections/work/mock-works';

@Injectable({
  providedIn: 'root',
})
export class WorkService {
  constructor() {}

  getWorks(): Observable<Work[]> {
    return of(WORKS);
  }

  getWork(id: number): Observable<Work> {
    return of(WORKS.find((work) => work.id === id));
  }
}
