import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './sections/home/home.component';
import { AboutComponent } from './sections/about/about.component';
import { ServicesComponent } from './sections/services/services.component';
import { ContactComponent } from './sections/contact/contact.component';
import { WorkComponent } from './sections/work/work.component';
import { WorkDetailsComponent } from './sections/work/work-details.component';
import { FrontComponent } from './sections/front/front.component';

const routes: Routes = [
  { path: '', component: FrontComponent },
  { path: 'work', component: WorkComponent },
  { path: 'work-details/:id', component: WorkDetailsComponent },
  { path: 'front', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [BrowserModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
