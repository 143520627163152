<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  attr.width="{{ this.svgWidthHeight }}"
  attr.height="{{ this.svgWidthHeight * 2 }}"
>
  <svg
    y="0"
    id="white-circle"
    attr.width="{{ this.svgWidthHeight }}"
    attr.height="{{ this.svgWidthHeight }}"
  >
    <circle
      attr.cx="{{ this.circle.cxy }}"
      attr.cy="{{ this.circle.cxy }}"
      attr.r="{{ this.circle.r }}"
      stroke="currentColor"
      stroke-width="1"
      fill="none"
    />
    <text
      id="white-text"
      x="50%"
      y="50%"
      text-anchor="middle"
      font-family="GTFRiposteThin, sans-serif"
      font-size="18px"
      fill="currentColor"
    >
      Design
    </text>
  </svg>
  <svg
    width="21"
    height="21"
    attr.x="{{ this.svgWidthHeight / 2 - 10.5 }}"
    attr.y="{{ this.svgWidthHeight - this.svgWidthHeight * 0.13 - 10.5 }}"
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.7324 21.3378L139.144 169.551"
      attr.stroke="{{ setSvgColor('currentColor', '#e4fd97') }}"
      stroke-width="20"
      stroke-linecap="square"
    />
    <path
      d="M169.917 53.3671L21.7031 138.778"
      attr.stroke="{{ setSvgColor('currentColor', '#e4fd97') }}"
      stroke-width="20"
      stroke-linecap="square"
    />
    <path
      d="M139.144 21.3378L53.7324 169.551"
      attr.stroke="{{ setSvgColor('currentColor', '#e4fd97') }}"
      stroke-width="20"
      stroke-linecap="square"
    />
    <path
      d="M169.917 138.778L21.7031 53.3671"
      attr.stroke="{{ setSvgColor('currentColor', '#e4fd97') }}"
      stroke-width="20"
      stroke-linecap="square"
    />
    <path
      d="M96.1241 10.0334V181.484"
      attr.stroke="{{ setSvgColor('currentColor', '#e4fd97') }}"
      stroke-width="20"
      stroke-linecap="square"
    />
    <path
      d="M181.849 95.7586H10.3989"
      attr.stroke="{{ setSvgColor('currentColor', '#e4fd97') }}"
      stroke-width="20"
      stroke-linecap="square"
    />
  </svg>

  <svg
    attr.y="{{ this.circle.r * 1.5 }}"
    id="blue-circle"
    attr.width="{{ this.svgWidthHeight }}"
    attr.height="{{ this.svgWidthHeight }}"
  >
    <circle
      attr.cx="{{ this.circle.cxy }}"
      attr.cy="{{ this.circle.cxy }}"
      attr.r="{{ this.circle.r }}"
      attr.stroke="{{ this.lowerCircleColor }}"
      stroke-width="1"
      fill="none"
    />
    <text
      id="blue-text"
      x="50%"
      y="50%"
      text-anchor="middle"
      font-family="GTFRiposteThin, sans-serif"
      font-size="18px"
      attr.fill="{{ this.lowerCircleColor }}"
    >
      Coding
    </text>
  </svg>
</svg>
