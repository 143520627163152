<div id="contact" class="page black">
  <div class="container">
    <div>
      <h2>Let's have a chat</h2>
      <p>
        We push the boundaries and are always ready to go the extra mile. We’ll
        treat you fairly, share some humor, and help you to achieve something
        that makes you proud.
      </p>
    </div>
    <div class="contact-info">
      <div>
        <h3>Start a project</h3>
        <p>ben@codingcompany.fi</p>
        <p>(+358) 40 352 0050</p>
      </div>
      <div>
        <h3>Join us</h3>
        <p>work@codingcompany.fi</p>
      </div>
    </div>
  </div>
  <div class="container">
    <contact-icon></contact-icon>
  </div>
</div>
