import { Component, OnInit } from '@angular/core';
import { Work } from './work.model';
import { WorkService } from '../../work.service';

@Component({
  selector: 'work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss'],
})
export class WorkComponent implements OnInit {
  works: Work[];

  constructor(private workService: WorkService) {}

  ngOnInit(): void {
    this.getWorks();
  }

  getWorks(): void {
    this.workService.getWorks().subscribe((works) => (this.works = works));
  }
}
