import {Work} from './work.model';

export const WORKS: Work[] = [
  {
    client: 'Member+',
    projectName: 'Identity Provider',
    summary:
      'This is a short summary of the project. What was done and why. lorem ipsum dolor sit amet lorem ipsum.',
    challenge:
      'Client name goes here_ provides high-quality products and services to their customers. Their products and services provide their customers the means to influence and better understand their environment. When it comes to online experience, the engineering industry as a whole lacks pull and appeal. _Client name goes here_ wanted to set the bar higher and provide a world-class customer experience and sales via seamless transition across online services and devices.',
    approach:
      'Client name goes here_ provides high-quality products and services to their customers. Their products and services provide their customers the means to influence and better understand their environment. When it comes to online experience, the engineering industry as a whole lacks pull and appeal. _Client name goes here_ wanted to set the bar higher and provide a world-class customer experience and sales via seamless transition across online services and devices.',
    highlights: [
      {
        title: 'Title goes here',
        description: 'Complete redesign, build, and relaunch on iOS',
      },
      {
        title: 'Title goes here',
        description: 'Modern technology stack for effortless updates',
      },
      {
        title: 'Title goes here',
        description: 'Growth-hacking for continuous improvement',
      },
    ],
    solution:
      'Client name goes here_ provides high-quality products and services to their customers. Their products and services provide their customers the means to influence and better understand their environment. When it comes to online experience, the engineering industry as a whole lacks pull and appeal. _Client name goes here_ wanted to set the bar higher and provide a world-class customer experience and sales via seamless transition across online services and devices.',
    images: ['https://source.unsplash.com/1600x900/?phone'],
    id: 1,
  },
  {
    client: 'Digital Lighthouse',
    projectName: 'Project name goes here lorem ipsum',
    summary: '',
    challenge: '',
    approach: '',
    highlights: [],
    solution: '',
    images: ['https://source.unsplash.com/1600x900/?internet'],
    id: 2,
  },
  {
    client: 'Random',
    projectName: 'Project name goes here lorem ipsum',
    summary: '',
    challenge: '',
    approach: '',
    highlights: [],
    solution: '',
    images: ['https://source.unsplash.com/1600x900/?computer'],
    id: 3,
  },
];
