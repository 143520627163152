import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './sections/home/home.component';
import { Page2Component } from './sections/page2/page2.component';
import { AboutComponent } from './sections/about/about.component';
import { CirclesComponent } from './sections/about/circles.component';
import { ServicesComponent } from './sections/services/services.component';
import { ContactComponent } from './sections/contact/contact.component';
import { ContactIconComponent } from './sections/contact/contact-icon.component';
import { RectangleComponent } from './components/rectangle/rectangle.component';
import { WallComponent } from './components/wall/wall.component';
import { FrontComponent } from './sections/front/front.component';
import { WorkComponent } from './sections/work/work.component';
import { WorkDetailsComponent } from './sections/work/work-details.component';
import { NavigationComponent } from './sections/navigation/navigation.component';
import { LogoComponent } from './sections/navigation/logo.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Page2Component,
    AboutComponent,
    CirclesComponent,
    ServicesComponent,
    ContactComponent,
    ContactIconComponent,
    RectangleComponent,
    WallComponent,
    FrontComponent,
    WorkComponent,
    WorkDetailsComponent,
    NavigationComponent,
    LogoComponent,
  ],
  imports: [BrowserModule, AppRoutingModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
