import { Component, OnInit, HostListener } from '@angular/core';
import { ThemeService } from 'src/app/theme/theme.service';

@Component({
  selector: 'app-circles',
  templateUrl: './circles.component.html',
  styleUrls: ['./circles.component.scss'],
})
export class CirclesComponent implements OnInit {
  svgWidthHeight = 287;
  circle = {
    cxy: 0,
    r: 0,
  };
  windowWidth;
  windowHeight;
  upperCircleColor = '#fff';
  lowerCircleColor = '#0832FF';
  asteriskColor = '#e4fd97';

  constructor(private themeService: ThemeService) {}

  ngOnInit(): void {
    this.calculateCircle();
    this.setSvgColor('currentColor', '#e4fd97');
  }

  @HostListener('window:resize', ['$event'])
  calculateCircle() {
    // this.svgWidthHeight = window.innerWidth - 128;

    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;

    if (this.windowWidth > 1023) {
      this.svgWidthHeight = 0.22 * this.windowWidth;
    }
    this.circle.cxy = this.svgWidthHeight / 2;
    this.circle.r = this.circle.cxy - 1;
  }

  setSvgColor(lightTheme: string, darkTheme: string) {
    return this.themeService.setSvgColor(lightTheme, darkTheme);
  }
}
