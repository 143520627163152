<div id="services" (mousemove)="MouseMove($event)" class="page transparent">
  <app-wall [MouseMoveEvent]="currentMouse"></app-wall>
  <div class="container">
    <div class="left">
      <p id="services-text">
        You don’t know how to build advanced web applications using custom code,
        APIs or databases. We do this heavy lifting for you so you can focus on
        your sweet spot. Our developers are technology independent and choose
        the best tools for each case.
      </p>

<!--      <a href="/work"-->
<!--        >See our work-->
<!--        <svg-->
<!--          width="25"-->
<!--          height="23"-->
<!--          viewBox="0 0 25 23"-->
<!--          fill="none"-->
<!--          xmlns="http://www.w3.org/2000/svg"-->
<!--        >-->
<!--          <path-->
<!--            d="M0 11.5H24M24 11.5L15.375 1M24 11.5L14.25 22"-->
<!--            attr.stroke="{{ setSvgColor() }}"-->
<!--            stroke-width="0.75"-->
<!--          />-->
<!--        </svg>-->
<!--        &lt;!&ndash; <span></span> &ndash;&gt;-->
<!--      </a>-->
    </div>

    <div class="right">
      <ul>
        <li>
          <h3>Modern web applications</h3>
          <p>
            We code with: ReactJS, Angular, Jquery, JavaScript, TypeScript, Saas
            CSS3 and HTML5
          </p>
        </li>
        <li>
          <h3>Mobile applications</h3>
          <p>
            We develop mobile applications for the client’s platform of choice —
            even for a C64.
          </p>
        </li>
        <li>
          <h3>Wordpress and Drupal</h3>
          <p>
            We rely on the world's most popular open source solutions in our
            website implementations. We know our way around both Wordpress and
            Drupal.
          </p>
        </li>
        <li>
          <h3>E-commerce</h3>
          <p>
            We craft custom-made online stores both with WooCommerce and Drupal
            Commerce.
          </p>
        </li>
      </ul>
    </div>
  </div>

  <div class="ticker-wrap">
    <div class="ticker">
      <div class="ticker__item">React</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Angular</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Vue.js</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Unity</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Webgl</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Node.js</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Wordpress</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Drupal</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Sql</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">C#</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Java</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">React</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Angular</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Vue.js</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Unity</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Webgl</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Node.js</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Wordpress</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Drupal</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Sql</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">C#</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Java</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">React</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Angular</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Vue.js</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Unity</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Webgl</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Node.js</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Wordpress</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Drupal</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Sql</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">C#</div>
      <div class="ticker__item">/</div>
      <div class="ticker__item">Java</div>
    </div>
  </div>
</div>
