<div class="chat-icon">
  <div id="ellipse">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 167 168"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M152.875 85.9175L151.984 94.7812L152.987 94.8821L153.765 87.1414L166.8 88.4525L166.913 87.3295L152.875 85.9175Z"
        fill="currentColor"
      />
      <path
        d="M151.13 99.9951L148.524 108.345L149.486 108.645L151.756 101.373L157.162 103.06L154.959 110.121L155.921 110.421L158.124 103.36L163.3 104.975L161.03 112.248L161.992 112.548L164.598 104.198L151.13 99.9951Z"
        fill="currentColor"
      />
      <path
        d="M145.29 115.637L144.756 116.631L156.296 122.832L154.122 126.88L155.009 127.357L159.884 118.284L158.996 117.807L156.831 121.837L145.29 115.637Z"
        fill="currentColor"
      />
      <path
        d="M151.107 133.217C150.643 132.667 150.155 132 149.643 131.216C149.135 130.451 148.686 129.73 148.297 129.052L149.064 127.989C149.4 128.397 149.766 128.819 150.164 129.255C150.564 129.71 150.962 130.147 151.357 130.564C151.762 130.989 152.136 131.367 152.478 131.697L151.369 133.233L151.107 133.217Z"
        fill="currentColor"
      />
      <path
        d="M134.072 131.266C131.979 133.42 131.789 136.046 133.568 137.773C137.543 141.635 141.349 133.783 144.197 136.55C145.353 137.673 145.321 139.355 144.043 140.671C142.582 142.174 140.661 142.359 139.304 141.182L138.847 142.086C140.437 143.631 142.828 143.368 144.766 141.373C146.521 139.566 146.631 137.341 145.113 135.866C141.253 132.117 137.622 140.166 134.383 137.02C133.024 135.7 133.151 133.66 134.78 131.983C136.395 130.32 138.719 130.358 140.553 131.999L141.038 131.065C138.957 129.072 136.15 129.127 134.072 131.266Z"
        fill="currentColor"
      />
      <path
        d="M120.316 143.437L119.308 143.945L122.312 149.902L116.194 152.988L113.19 147.031L112.182 147.539L118.535 160.136L119.543 159.628L116.647 153.888L122.766 150.802L125.661 156.542L126.669 156.034L120.316 143.437Z"
        fill="currentColor"
      />
      <path
        d="M97.2453 152.435L96.0735 152.734L104.791 165.072L105.963 164.773L107.694 149.773L106.523 150.072L106.119 153.876L99.42 155.583L97.2453 152.435ZM105.997 154.948L105.059 163.756L100.02 156.47L105.997 154.948Z"
        fill="currentColor"
      />
      <path
        d="M88.5554 153.573L87.4078 153.627L83.0478 167.952L84.296 167.894L88.0762 154.988L92.9936 167.491L94.2418 167.433L88.5554 153.573Z"
        fill="currentColor"
      />
      <path
        d="M77.4029 153.692L68.7698 152.284L68.6076 153.279L76.1267 154.505L75.215 160.095L67.9147 158.904L67.7525 159.899L75.0528 161.089L74.18 166.44L66.6609 165.214L66.4986 166.209L75.1317 167.617L77.4029 153.692Z"
        fill="currentColor"
      />
      <path
        d="M48.318 144.33L47.2464 143.769L45.4938 158.774L46.5653 159.335L57.8727 149.327L56.8011 148.767L53.9597 151.329L47.8339 148.125L48.318 144.33ZM53.1533 152.045L46.5722 157.974L47.6883 149.186L53.1533 152.045Z"
        fill="currentColor"
      />
      <path
        d="M34.3377 133.074C32.5261 131.209 29.9235 130.815 27.7529 132.08L28.1234 133.069C29.8492 132.011 31.9999 132.113 33.6148 133.776C35.5668 135.785 35.0512 138.899 32.3332 141.539C29.6442 144.151 26.5455 144.548 24.5935 142.539C23.0207 140.92 22.9935 138.867 24.0308 137.129L23.0833 136.616C21.7119 138.791 22.1013 141.419 23.8707 143.241C26.3001 145.742 29.9969 145.382 33.1196 142.349C36.2713 139.288 36.7671 135.575 34.3377 133.074Z"
        fill="currentColor"
      />
      <path
        d="M27.6991 126.218L27.1073 125.257L21.4268 128.755L17.8335 122.92L23.514 119.422L22.9221 118.461L10.909 125.859L11.5008 126.82L16.9754 123.448L20.5687 129.283L15.0941 132.655L15.686 133.616L27.6991 126.218Z"
        fill="currentColor"
      />
      <path
        d="M16.7936 104.024L16.3987 102.881L4.82771 112.593L5.22261 113.736L20.3148 114.215L19.9199 113.072L16.0946 112.987L13.8371 106.453L16.7936 104.024ZM15.0171 112.954L6.16157 112.751L13.0031 107.125L15.0171 112.954Z"
        fill="currentColor"
      />
      <path
        d="M15.0332 96.1795L14.8729 95.0623L1.90509 96.923L1.25241 92.3743L0.254888 92.5174L1.7177 102.712L2.71522 102.569L2.0654 98.0402L15.0332 96.1795Z"
        fill="currentColor"
      />
      <path
        d="M15.1042 73.8907C15.5326 71.3263 14.3075 68.9965 12.0017 67.9983L11.4249 68.8828C13.3 69.6456 14.4921 71.4386 14.1103 73.7247C13.6487 76.4879 10.8351 77.9183 7.09774 77.294C3.40019 76.6763 1.24398 74.4158 1.70556 71.6526C2.07748 69.4261 3.71477 68.1875 5.7294 67.9927L5.58069 66.9257C3.01603 67.1103 1.13 68.9817 0.711591 71.4865C0.137107 74.9256 2.61785 77.69 6.91178 78.4072C11.2455 79.1311 14.5297 77.3298 15.1042 73.8907Z"
        fill="currentColor"
      />
      <path
        d="M18.5712 60.5155C20.0101 56.9667 18.0045 54.4572 13.802 52.7533C9.59947 51.0494 6.41255 51.4536 4.97369 55.0024C3.52726 58.5699 5.5328 61.0794 9.73534 62.7834C13.9379 64.4873 17.1248 64.083 18.5712 60.5155ZM17.712 60.1672C16.2429 63.7907 12.4195 62.6537 10.1594 61.7374C7.89939 60.8211 4.36372 58.9743 5.83287 55.3508C7.30202 51.7273 11.1179 52.8829 13.3779 53.7993C15.638 54.7156 19.1812 56.5437 17.712 60.1672Z"
        fill="currentColor"
      />
      <path
        d="M23.0202 49.7175L25.157 46.7112C27.4106 43.5407 26.4583 40.0448 23.0249 37.6044C19.5586 35.1406 15.9112 35.367 13.6576 38.5375L11.5207 41.5438L23.0202 49.7175ZM22.8527 48.2137L12.996 41.2077L14.5257 39.0557C16.3589 36.4765 19.3976 36.4109 22.371 38.5244C25.3115 40.6145 26.2155 43.4825 24.3823 46.0617L22.8527 48.2137Z"
        fill="currentColor"
      />
      <path
        d="M33.2518 36.5336L34.0557 35.7414L24.1523 25.693L23.3485 26.4853L33.2518 36.5336Z"
        fill="currentColor"
      />
      <path
        d="M37.1539 14.9763L44.0859 24.9588L31.2604 19.0687L30.3334 19.7125L38.3804 31.3009L39.3075 30.6571L32.5365 20.9063L45.201 26.5646L46.1281 25.9209L38.081 14.3325L37.1539 14.9763Z"
        fill="currentColor"
      />
      <path
        d="M56.2164 20.8874C58.0551 20.1659 59.2482 18.5718 59.2294 16.8687L60.1056 19.1014L61.1187 18.7038L58.4387 11.8745L51.403 14.6355L51.7712 15.5736L57.8688 13.1807C59.091 16.2952 58.4187 18.9407 55.9609 19.9052C53.3155 20.9433 50.7025 19.3056 49.2963 15.7221C47.9047 12.1761 48.7211 9.23594 51.3666 8.19779C53.3553 7.41735 55.3154 8.16375 56.5173 9.79221L57.3376 9.12389C55.7754 7.07388 53.3624 6.332 50.9984 7.2597C47.8089 8.51136 46.6405 12.0443 48.2456 16.1344C49.8654 20.262 53.0457 22.1317 56.2164 20.8874Z"
        fill="currentColor"
      />
      <path
        d="M79.4917 15.4784C82.0897 15.378 84.1228 13.7061 84.6329 11.2459L83.6499 10.8603C83.283 12.8511 81.7689 14.3819 79.4528 14.4714C76.6534 14.5795 74.6824 12.1142 74.5362 8.3279C74.3915 4.5819 76.1681 2.01225 78.9675 1.90416C81.2232 1.81706 82.768 3.1693 83.367 5.10264L84.3817 4.74074C83.6811 2.26674 81.4663 0.799185 78.9287 0.897169C75.4445 1.0317 73.2404 4.02126 73.4084 8.37145C73.5779 12.7619 76.0075 15.6129 79.4917 15.4784Z"
        fill="currentColor"
      />
      <path
        d="M94.0315 16.2062C97.7958 16.9093 99.8556 14.4441 100.688 9.98634C101.521 5.52861 100.49 2.48608 96.7255 1.78299C92.9413 1.0762 90.8816 3.54146 90.049 7.99919C89.2164 12.4569 90.2474 15.4995 94.0315 16.2062ZM94.2018 15.2949C90.3582 14.577 90.7108 10.6037 91.1585 8.20642C91.6063 5.80915 92.7117 1.97646 96.5552 2.69435C100.399 3.41224 100.026 7.38185 99.5787 9.77912C99.1309 12.1764 98.0453 16.0128 94.2018 15.2949Z"
        fill="currentColor"
      />
      <path
        d="M105.5 18.2983L106.524 18.7726L111.352 8.34794L111.013 19.2964L111.507 19.5251L119.637 12.1846L114.809 22.6093L115.833 23.0836L121.762 10.2814L120.756 9.81553L112.004 17.7788L112.435 5.96192L111.429 5.49609L105.5 18.2983Z"
        fill="currentColor"
      />
      <path
        d="M121.796 26.501L122.684 27.1978L126.392 22.4728L128.58 24.1899C131.323 26.3425 133.505 26.4669 135.297 24.1837C137.002 22.0115 136.325 19.9686 133.582 17.816L130.506 15.4022L121.796 26.501ZM127.014 21.6801L130.771 16.8917L132.944 18.5964C135.068 20.2637 135.666 21.8855 134.347 23.5662C133.04 25.231 131.311 25.052 129.186 23.3847L127.014 21.6801Z"
        fill="currentColor"
      />
      <path
        d="M138.104 41.8255L138.888 42.7462L146.127 29.4865L145.343 28.5659L131.113 33.6162L131.897 34.5369L135.491 33.2236L139.973 38.4867L138.104 41.8255ZM136.506 32.8617L144.827 29.8256L140.505 37.5572L136.506 32.8617Z"
        fill="currentColor"
      />
      <path
        d="M157.976 47.0705L147.228 52.7432L154.627 40.725L154.1 39.7268L141.623 46.312L142.15 47.3101L152.648 41.7692L145.499 53.6557L146.025 54.6539L158.503 48.0687L157.976 47.0705Z"
        fill="currentColor"
      />
      <path
        d="M149.538 63.598L149.834 64.6874L155.222 63.2271L164.632 65.3547L164.321 64.207L156.042 62.3994L162.286 56.698L161.975 55.5503L154.927 62.1377L149.538 63.598Z"
        fill="currentColor"
      />
    </svg>
  </div>
  <div id="logomark">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 69 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.6269 23.9545H66.2349L45.499 2.57056L16.987 31.0825L45.499 59.5945L66.2349 37.0343H52.6269L29.947 59.5945L2.08301 31.0825L29.947 2.57056L52.6269 23.9545Z"
        stroke="currentColor"
        stroke-width="2.23942"
      />
    </svg>
  </div>
</div>
