import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'front',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.scss'],
})
export class FrontComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
