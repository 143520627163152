import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'contact-icon',
  templateUrl: './contact-icon.component.html',
  styleUrls: ['./contact-icon.component.scss'],
})
export class ContactIconComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
