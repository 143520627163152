<svg
  class="logo"
  width="183"
  height="49"
  viewBox="0 0 183 49"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M42.2154 18.9741H53.1262L36.5002 2L13.6394 24.6322L36.5002 47.2644L53.1262 29.3566H42.2154L24.0307 47.2644L1.68945 24.6322L24.0307 2L42.2154 18.9741Z"
    stroke="currentColor"
    stroke-width="2"
  />
  <path
    d="M76.5935 22.1445C80.0981 22.1445 83.6276 19.4601 83.6276 16.0301H82.6085C82.6085 17.4468 79.8993 19.0376 76.4444 19.0376C70.2305 19.0376 69.7085 14.9613 69.7085 13.0474C69.7085 11.1335 70.2056 7.10694 76.4444 7.10694C79.8496 7.10694 82.5837 8.47399 82.5837 10.015H83.6027C83.6027 6.06301 80.0484 4 76.5935 4C71.9207 4 68.6895 7.28093 68.6895 13.0474C68.6895 18.8139 71.8461 22.1445 76.5935 22.1445Z"
    attr.fill="{{ logoTextColor }}"
  />
  <path
    d="M92.8737 22.1445C97.3974 22.1445 100.455 18.6647 100.455 13.0723C100.455 7.47977 97.3974 4 92.8737 4C88.3251 4 85.2679 7.47977 85.2679 13.0723C85.2679 18.6647 88.3251 22.1445 92.8737 22.1445ZM92.8737 19.0624C87.1569 19.0624 86.2869 14.9861 86.2869 13.0723C86.2869 11.1584 87.1569 7.08208 92.8737 7.08208C98.5904 7.08208 99.4355 11.1584 99.4355 13.0723C99.4355 14.9861 98.5904 19.0624 92.8737 19.0624Z"
    attr.fill="{{ logoTextColor }}"
  />
  <path
    d="M103.107 21.7717H108.227C113.521 21.7717 116.902 18.6399 116.902 13.122C116.902 7.33064 113.521 4.37283 108.227 4.37283H103.107V21.7717ZM104.201 18.6647V7.47977H108.525C113.994 7.47977 115.883 9.04567 115.883 13.122C115.883 16.726 114.441 18.6647 108.525 18.6647H104.201Z"
    attr.fill="{{ logoTextColor }}"
  />
  <path
    d="M119.564 21.7717H120.658V4.37283H119.564V21.7717Z"
    attr.fill="{{ logoTextColor }}"
  />
  <path
    d="M124.03 21.7717H125.124V8.9711L135.538 21.7717H137.154V4.37283H136.06V17.4965L125.646 4.37283H124.03V21.7717Z"
    attr.fill="{{ logoTextColor }}"
  />
  <path
    d="M147.49 22.1445C151.466 22.1445 153.48 19.7087 153.853 18.2422V21.7717H154.896V12.0283H143.885V14.7624H153.877C153.853 17.2977 151.243 19.0376 147.713 19.0376C141.897 19.0376 140.903 14.9613 140.903 13.0474C140.903 11.2329 141.897 7.10694 147.713 7.10694C151.541 7.10694 153.753 8.5237 153.853 10.2387H154.872C154.872 6.08786 151.442 4 147.713 4C143.19 4 139.884 7.38035 139.884 13.0474C139.884 18.7145 142.891 22.1445 147.49 22.1445Z"
    attr.fill="{{ logoTextColor }}"
  />
  <path
    d="M76.5935 47C80.0981 47 83.6276 44.3156 83.6276 40.8856H82.6085C82.6085 42.3023 79.8993 43.8931 76.4444 43.8931C70.2305 43.8931 69.7085 39.8168 69.7085 37.9029C69.7085 35.989 70.2056 31.9624 76.4444 31.9624C79.8496 31.9624 82.5837 33.3295 82.5837 34.8705H83.6027C83.6027 30.9185 80.0484 28.8555 76.5935 28.8555C71.9207 28.8555 68.6895 32.1364 68.6895 37.9029C68.6895 43.6694 71.8461 47 76.5935 47Z"
    attr.fill="{{ logoTextColor }}"
  />
  <path
    d="M92.8737 47C97.3974 47 100.455 43.5202 100.455 37.9277C100.455 32.3353 97.3974 28.8555 92.8737 28.8555C88.3251 28.8555 85.2679 32.3353 85.2679 37.9277C85.2679 43.5202 88.3251 47 92.8737 47ZM92.8737 43.9179C87.1569 43.9179 86.2869 39.8416 86.2869 37.9277C86.2869 36.0139 87.1569 31.9376 92.8737 31.9376C98.5904 31.9376 99.4355 36.0139 99.4355 37.9277C99.4355 39.8416 98.5904 43.9179 92.8737 43.9179Z"
    attr.fill="{{ logoTextColor }}"
  />
  <path
    d="M103.107 46.6272H104.201V34.1746L109.967 45.111H111.831L118.07 34.1249V46.6272H120.978V29.2283H119.636L112.105 42.526L104.623 29.2283H103.107V46.6272Z"
    attr.fill="{{ logoTextColor }}"
  />
  <path
    d="M124.37 46.6272H125.464V39.6676H130.733C135.555 39.6676 137.32 37.4058 137.32 34.3486C137.32 29.5266 133.119 29.2283 130.733 29.2283H124.37V46.6272ZM125.464 36.6353V32.2607H130.559C134.71 32.2607 136.375 32.4844 136.375 34.4231C136.375 36.4364 134.66 36.6353 130.559 36.6353H125.464Z"
    attr.fill="{{ logoTextColor }}"
  />
  <path
    d="M135.804 46.6272H137.121L138.289 43.1474H147.237L148.53 46.6272H151.861L145.423 29.2283H142.043L135.804 46.6272ZM139.309 40.1896L142.739 30.3717L146.144 40.1896H139.309Z"
    attr.fill="{{ logoTextColor }}"
  />
  <path
    d="M153.133 46.6272H154.227V33.8266L164.642 46.6272H166.257V29.2283H165.164V42.352L154.749 29.2283H153.133V46.6272Z"
    attr.fill="{{ logoTextColor }}"
  />
  <path
    d="M174.306 46.6272H175.4V40.0156L182.782 29.2283H179.6L174.058 37.5549L168.987 29.2283H167.744L174.306 40.0156V46.6272Z"
    attr.fill="{{ logoTextColor }}"
  />
</svg>
