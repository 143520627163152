<!-- previous color setting: [ngStyle]="{
    'background-color': !backgroundColor ? 'transparent' : '#121212'
  }" -->
<nav [ngClass]="{ black: backgroundColor }">
  <div class="logo-section">
    <a (click)="goTo('#home')">
      <logo></logo>
    </a>
  </div>
  <div *ngIf="!isMobile()" class="color-mode">
    <label class="switch">
      <input
        type="checkbox"
        [checked]="mode == 'dark'"
        (change)="toggleTheme()"
      />
      <span class="slider round"></span>
    </label>
    <span class="mode-name">{{ mode == "dark" ? "Dark" : "Light" }} mode</span>
  </div>
  <ul id="menu" [ngClass]="{ open: open }">
    <!-- <li><a href="#home">home</a></li> -->
    <li>
      <a (click)="goTo('#about')">About Us</a>
    </li>
    <li>
      <a (click)="goTo('#services')">Services</a>
    </li>
<!--    <li><a routerLink="/work" (click)="open = false">Work</a></li>-->
    <li>
      <a (click)="goTo('#contact')">Let's Talk</a>
    </li>
    <li *ngIf="isMobile()">
      <div class="color-mode">
        <label class="switch">
          <input
            type="checkbox"
            [checked]="mode == 'dark'"
            (change)="toggleTheme()"
          />
          <span class="slider round"></span>
        </label>
        <span class="mode-name"
          >{{ mode == "dark" ? "Dark" : "Light" }} mode</span
        >
      </div>
    </li>
  </ul>
  <a
    href="javascript:void(0);"
    class="icon"
    (click)="open = true"
    *ngIf="!open"
  >
    <!-- Hamburger icon -->
    <svg
      width="46"
      height="24"
      viewBox="0 0 46 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="1" y1="1" x2="45" y2="1" stroke="currentColor" />
      <line x1="1" y1="11" x2="45" y2="11" stroke="currentColor" />
      <line x1="1" y1="21" x2="45" y2="21" stroke="currentColor" />
    </svg>
    <!-- <img src="./assets/icons/hamburger.svg" /> -->
    <span>Menu</span>
  </a>
  <a
    href="javascript:void(0);"
    class="icon"
    (click)="open = false"
    *ngIf="open"
  >
    <!-- Close icon -->
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1.1744"
        y1="0.678624"
        x2="29.4628"
        y2="28.967"
        stroke="currentColor"
        stroke-width="0.908989"
      />
      <line
        x1="0.535069"
        y1="29.4157"
        x2="28.8162"
        y2="1.13459"
        stroke="currentColor"
        stroke-width="0.908989"
      />
    </svg>
    <!-- <img src="./assets/icons/close.svg" /> -->
    Close
  </a>
</nav>
