import { Component, OnInit, HostListener } from '@angular/core';
import { ThemeService } from 'src/app/theme/theme.service';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  open: boolean = false;
  backgroundColor: boolean = false;
  mode: string = 'dark';

  constructor(private themeService: ThemeService) {}

  ngOnInit(): void {}

  goTo(location: string) {
    this.open = false;
    if (!location.includes('/')) {
      location = '/front' + location;
    }
    window.location.href = location;
  }

  toggleTheme() {
    if (this.themeService.isDarkTheme()) {
      this.themeService.setLightTheme();
    } else {
      this.themeService.setDarkTheme();
    }
    this.mode = this.themeService.getActiveTheme().name;
  }

  isMobile() {
    return CommonService.isMobile();
  }
}
