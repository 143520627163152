import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Work } from './work.model';
import { WorkService } from '../../work.service';

@Component({
  selector: 'work-details',
  templateUrl: './work-details.component.html',
  styleUrls: ['./work-details.component.scss'],
})
export class WorkDetailsComponent implements OnInit {
  @Input() work: Work;

  constructor(
    private route: ActivatedRoute,
    private workService: WorkService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.getWork();
  }

  getWork(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.workService.getWork(id).subscribe((work) => (this.work = work));
  }

  goBack(): void {
    this.location.back();
  }
}
