import {
  Component,
  OnInit,
  HostListener,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import * as THREE from 'three';
import { ThemeService } from 'src/app/theme/theme.service';
import { LuminanceFormat } from 'three';

@Component({
  selector: 'app-wall',
  templateUrl: './wall.component.html',
  styleUrls: ['./wall.component.scss'],
})
export class WallComponent implements OnInit, OnChanges {
  private scene;
  private camera;
  private renderer;
  private light1;
  private light2;
  public target: Element;
  private width;
  private height;
  private area;

  constructor(private themeService: ThemeService) {}

  @Input() MouseMoveEvent; //Get Mouse Move Event from parent

  ngOnInit(): void {
    this.init();
  }

  //If mouse is moved, reposition
  ngOnChanges(changes: SimpleChanges) {
    this.reposition(changes.MouseMoveEvent.currentValue);
  }

  init() {
    this.initMeasurements();

    //scene
    this.scene = new THREE.Scene();
    //this.scene.background = new THREE.Color('#666666');

    //Set up scene camera
    this.createCamera();

    //Initialize renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    //this.renderer.setClearColor('#121212', 1);
    this.renderer.setSize(this.width, this.height);

    //Add to DOM
    this.target.appendChild(this.renderer.domElement);

    //Create Objects
    this.createGeometry();

    //Set animation function
    this.animate();
  }

  initMeasurements() {
    this.target = document.getElementById('wall');
    this.area = document.getElementById('services');

    this.width = window.innerWidth;
    this.height = this.area.offsetHeight;

    let factor = 200.5;
    this.camera = new THREE.OrthographicCamera(
      -this.width / factor,
      this.width / factor,
      this.height / factor,
      -this.height / factor,
      1,
      1000
    );
  }

  degToRad(degrees) {
    return (degrees * Math.PI) / 180;
  }

  @HostListener('window:change', ['$event']) // create wall again when theme is changed
  createGeometry() {
    this.scene.remove.apply(this.scene, this.scene.children); // remove any pre-existing scenes
    //Create Wall LAYER 1
    let geometry = new THREE.PlaneGeometry(100, 100, 1, 1);
    let material = new THREE.MeshStandardMaterial({
      // color: '#121212',
      color: this.setSvgColor('#ffffff', '#121212'),
      metalness: 0.2,
      roughness: 0.2,
    });
    let wall = new THREE.Mesh(geometry, material);
    wall.position.z = 0.5;
    wall.layers.set(0);
    this.scene.add(wall);

    //This spot light shows on the the wall LAYER 1
    this.light1 = new THREE.SpotLight(
      '#ffffff', //Color
      0.3, //Intensity, default = 1
      55, //Distance, default = 0 no limit
      this.degToRad(180),
      0,
      40
    );
    this.light1.layers.set(0);
    this.light1.position.set(1, 9, 4);
    this.light1.target.updateMatrixWorld();
    this.scene.add(this.light1);

    //This spot light shows on the the text LAYER 2
    this.light2 = new THREE.SpotLight(
      '#FFFFFF', //Color
      3, //Intensity, default = 1
      55, //Distance, default = 0 no limit
      this.degToRad(180),
      0,
      40
    );
    this.light2.layers.set(1);
    this.light2.position.set(1, 9, 4);
    this.light2.target.updateMatrixWorld();
    this.scene.add(this.light2);

    //This light gives the wall intended color using intensity LAYER 1
    let ambiance1 = new THREE.AmbientLight('#ffffff', 1.25);
    ambiance1.layers.set(0);
    this.scene.add(ambiance1);

    let ambiance2 = new THREE.AmbientLight('#202022', 1);
    ambiance2.layers.set(1);
    this.scene.add(ambiance2);

    // Add helper to see where light is on the screen
    // let help = new THREE.PointLightHelper(this.light);
    // this.scene.add(help);

    //Get Font Size from parent
    let h2FontSize = window.getComputedStyle(
      document.getElementsByTagName('H6')[0]
    ).fontSize;
    let rem = parseFloat(h2FontSize.replace('px', '')) / 18.0;
    this.createText(['This is how', 'we do it'], rem, rem);
  }

  createText(text: Array<string>, lineHeightRem: number, fontSizeRem: number) {
    let px18 = 1 / 8; //defines how big is 18px
    let previousPosition: number = 0;
    let textPosition = document.getElementById('services-text');

    new THREE.FontLoader().load(
      '../../../assets/fonts/gtf-opposite-black.json',
      (font) => {
        for (let str of text) {
          let textGeo = new THREE.TextGeometry(str, {
            font: font,
            size: px18 * fontSizeRem,
            height: 1,
            curveSegments: 21,
            bevelEnabled: false,
          });

          textGeo.computeBoundingBox();
          textGeo.center();

          let textMesh = new THREE.Mesh(
            textGeo,
            new THREE.MeshPhongMaterial({ color: 0xffffff })
          );

          let textHeight =
            this.reverseY(textMesh.geometry.boundingBox.min.y) -
            this.reverseY(textMesh.geometry.boundingBox.max.y);

          let textWidth =
            this.reverseX(textMesh.geometry.boundingBox.max.x) -
            this.reverseX(textMesh.geometry.boundingBox.min.x);

          // Text local coordinates 0,0 are in the middle of the geometry
          textMesh.position.x = this.translateX(
            textPosition.offsetLeft + textWidth / 2
          );
          textMesh.position.y =
            this.translateY(
              textPosition.offsetTop - textHeight - 0 + previousPosition
            ) +
            lineHeightRem * px18 * text.length;

          textMesh.position.z = 1;
          textMesh.layers.set(1);
          previousPosition += textHeight + 10;

          this.scene.add(textMesh);
        }
      }
    );
  }

  createCamera() {
    this.camera.position.z = 10;
  }

  animate() {
    requestAnimationFrame(() => this.animate());
    this.renderer.autoClear = true;
    this.renderLayer(0);
    this.renderLayer(1);
  }

  renderLayer(layer: number) {
    this.camera.layers.set(layer);
    this.renderer.render(this.scene, this.camera);
    this.renderer.autoClear = false;
  }

  translateX(x) {
    return (x - this.width / 2) * 0.01;
  }

  translateY(y) {
    return -1 * (y - this.height / 2) * 0.01;
  }

  reverseY(y) {
    return (y / 0.01 - this.height / 2) * -1;
  }

  reverseX(x) {
    return x / 0.01 - this.width / 2;
  }

  //When mouse is moved on the parent, update
  reposition(event) {
    if (!event) return;
    let x = event.clientX;
    let y = event.clientY - this.target.getBoundingClientRect().top;
    this.light1.position.x = this.translateX(x);
    this.light1.position.y = this.translateY(y);
    this.light2.position.x = this.translateX(x);
    this.light2.position.y = this.translateY(y);
  }

  @HostListener('window:touchmove', ['$event'])
  repositionOnTouch(event) {
    let touchmoveObj = {
      // clientX: event.originalEvent.touches[0].pageX,
      // clientY: event.originalEvent.touches[0].pageY,
      clientX: event.touches[0].clientX,
      clientY: event.touches[0].clientY,
    };
    this.reposition(touchmoveObj);
  }

  @HostListener('window:resize', ['$event'])
  resize() {
    this.initMeasurements();
    this.createCamera();
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.width, this.height);
    this.scene.remove.apply(this.scene, this.scene.children);
    this.createGeometry();
  }

  // change color based on color theme
  setSvgColor(lightTheme: string, darkTheme: string) {
    return this.themeService.setSvgColor(lightTheme, darkTheme);
  }
}
