<div *ngIf="work" class="page black" id="work-details">
  <!-- <button (click)="goBack()">go back</button> -->
  <div class="intro container">
    <div class="text-box">
      <h3>Client: {{ work.client }}</h3>
      <h2>{{ work.projectName }}</h2>
      <p>{{ work.summary }}</p>
    </div>

    <img src="{{ work.images[0] }}" />
  </div>
  <div class="step container">
    <h3>01. The challenge</h3>
    <p>{{ work.challenge }}</p>
  </div>
  <img class="full-width" src="{{ work.images[0] }}" />
  <div class="step container">
    <h3>02. The approach</h3>
    <p>{{ work.approach }}</p>
  </div>
  <div class="highlight container">
    <h2>Project highlights</h2>
    <ul id="highlight-list">
      <li *ngFor="let highlight of work.highlights">
        <h3>{{ highlight.title }}</h3>
        <p>{{ highlight.description }}</p>
      </li>
    </ul>
  </div>
  <div class="step container">
    <h3>03. The solution</h3>
    <p>{{ work.solution }}</p>
  </div>
  <img class="full-width" src="{{ work.images[0] }}" />
</div>
