<div id="home" class="page transparent">
  <div class="container transparent">
    <h1>We build creative code so you can do your best creative work.</h1>
  </div>
  <div
    class="scroll-down"
    [ngStyle]="{
      display: scrollDown ? 'block' : 'none'
    }"
  >
    <div>
      <span></span>
      Scroll to explore
    </div>
  </div>

  <app-rectangle></app-rectangle>
</div>
